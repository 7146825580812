<template>
  <div>
    <div class="page-wrapper chiller-theme" :class="{ toggled: isActive }">
      <main
        style="margin-top: 100px"
      >
        <div class="mt-5">
          <div class="slider">
            <div class="container" align="center">
              <div
                class="slide"
                :class="{ active: active == 'learn', slide11: learnSlide == 'slide11', slide111 : learnSlide == 'slide111'}"
              >
                <div class="card">
                  <div class="card-body">
                    <div class="gdLogoArea center">
                      <span class="gdLogo"
                        ><img src="@/assets/Icons/book-reader-solid.svg" height="55px"></span>
                    </div>
                    <h5>Collecting</h5>
                    <p>We are Collecting & learning from your business data</p>
                    <div class="progress">
                      <div
                        class="progress-bar progress-bar-striped progress-bar-animated"
                        role="progressbar"
                        aria-valuenow="0"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        :style="{ width: progressLearn + '%' }"
                      >
                        {{ progressLearn }}%
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="slide slide2"
                :class="{ active: active == 'train', slide2: trainSlide == 'slide2', slide222: trainSlide =='slide222'}"
              >
                <div class="card">
                  <div class="card-body">
                    <div class="gdLogoArea center">
                      <span class="gdLogo"
                        ><i class="fas fa-chalkboard-teacher"></i
                      ></span>
                    </div>
                    <h5>Computing</h5>
                    <p>We are Computing our prediction for your business data</p>
                    <div class="progress">
                      <div
                        class="progress-bar progress-bar-striped progress-bar-animated"
                        role="progressbar"
                        aria-valuenow="0"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        :style="{ width: progressTrain + '%' }"
                      >
                        {{ progressTrain }}%
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="slide slide3"
                :class="{ active: active == 'test', slide33: testSlide == 'slide33', slide3: testSlide== 'slide3' }"
              >
                <div class="card">
                  <div class="card-body">
                    <div class="gdLogoArea center">
                      <span class="gdLogo"
                        ><i class="fas fa-user-edit"></i
                      ></span>
                    </div>
                    <h5>Plotting</h5>
                    <p>We are Plotting your plan from our intelligence Engine</p>
                    <div class="progress">
                      <div
                        class="progress-bar progress-bar-striped progress-bar-animated"
                        role="progressbar"
                        aria-valuenow="0"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        :style="{ width: progressTest + '%' }"
                      >
                        {{ progressTest }}%
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
var timer;
export default {
  components: {
  },
  data() {
    return {
      isActive: false,
      padding: 0,
      menuMargin: 70,
      progressLearn: 0,
      progressTest: 0,
      progressTrain: 0,
      active: "learn",
      learnSlide: '',
      testSlide: 'slide3',
      trainSlide: 'slide2'
    };
  },
  methods: {
    progress() {
      var self = this;
      var count = 0;
      timer = setInterval(function () {
        if (count == 0) {
          if (self.progressLearn == 100) {
              self.learnSlide = 'slide11'
              self.testSlide = 'slide33'
            count++;
          } else {
            ++self.progressLearn;
          }
        } else if (count == 1) {
          self.active = "train";
          if (self.progressTrain == 100) {
              self.learnSlide = 'slide111'
              self.trainSlide = 'slide222'
            count++;
          } else {
            ++self.progressTrain;
          }
        } else if (count == 2) {
          self.active = "test";
          self.learnSlide = 'slide111'
          if (self.progressTest == 100) {
            clearInterval(timer);
            self.$router.push("/digital-planning/performance/dashboard")
          } else {
            ++self.progressTest;
          }
        }
      }, 50);
    },
  },
  beforeDestroy(){
    clearInterval(timer)
  },
  created() {
    this.progress();
  },
};
</script>

<style scoped>
.center {
  text-align: center;
}
.gdLogo {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  border: 1px solid #d92e72;
  font-size: 3rem;
  color: #d92e72;
  text-align: center;
  padding-top: 10px;
  display: inline-block;
}
.slide .progress {
  height: 2rem;
  margin-top: 30px;
  font-size: 1rem;
}
.progress {
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.65625rem;
  background-color: #f1f3f8;
}
.card {
  background-color: #3c4c66;
  box-shadow: none;
  border: 1px solid #ccc;
  border-radius: 10px;
}
.slide .card {
  margin: 0;
}
.slider .card-body {
  padding: 100px 30px 70px;
}
.slide {
  display: inline-block;
  position: absolute;
  backface-visibility: hidden;
  width: 300px;
  transition: 0.4s all;
}
.slider {
  perspective: 1000px;
  position: relative;
  width: 100%;
  height: 460px;
}
.slider {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}
.slide.active {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  padding: 0;
  margin: 0;
  transform: translate3d(-230px, 0px, 1px);
  z-index: 3;
  -webkit-transform-origin-x: initial;
}
.slide h5 {
  text-align: center;
  margin: 30px 0;
  color: #d92e72;
}
.slide p {
  text-align: center;
  margin: 20px 0;
}
.slide2{
transform: translate3d(10px, 0px, -240px);
    z-index: 2;
    -webkit-transform-origin-x: -80px;
}
.slide3{
transform: translate3d(130px, 0px, -360px);
    z-index: 1;
    -webkit-transform-origin-x: -80px;
}
.slide11{
    transform: translate3d(-470px, 0px, -240px);
    z-index: 1;
    -webkit-transform-origin-x: -80px;
}
.slide222{
    transform: translate3d(-470px, 0px, -240px);
    z-index: 2;
    -webkit-transform-origin-x: -80px;
}
.slide111{
transform: translate3d(-590px, 0px, -360px);
    z-index: 1;
    -webkit-transform-origin-x: -80px;
}
.slide33{
    transform: translate3d(10px, 0px, -240px);
    z-index: 1;
    -webkit-transform-origin-x: -80px;
}

.card {
  background-color: #fff;
}
</style>